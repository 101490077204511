import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

/* eslint-disable max-len */
import lhhMessages from '../../components/CaseStudy/messages/LufthansaHolidays/LufthansaHolidays.lang'
const lhhOverviewImg = <StaticImage src='../../images/casestudy/lufthansaholidays/design.png' alt='' objectFit='cover' className='image-teaser' />
const lhhOverviewImg2 = <StaticImage src='../../images/casestudy/lufthansaholidays/design.png' alt='' objectFit='cover' className='image' />
const lhhMarketingImg = <StaticImage src='../../images/casestudy/lufthansaholidays/checkitout.png' alt='' objectFit='cover' className='image' />

import aldianaMessages from '../../components/CaseStudy/messages/Aldiana/Aldiana.lang'
const aldianaOverviewImg = <StaticImage src='../../images/casestudy/aldiana/aldiana-responsive@2x.png' alt='' objectFit='cover' className='image-teaser' />
const aldianaOverviewImg2 = <StaticImage src='../../images/casestudy/aldiana/aldiana-responsive@2x.png' alt='' objectFit='cover' className='image' />
const aldianaMarketingImg = <StaticImage src='../../images/casestudy/aldiana/aldiana-marketing@2x.png' alt='' objectFit='cover' className='image' />

import bedfinderMessages from '../../components/CaseStudy/messages/Bedfinder/Bedfinder.lang'
const bedfinderOverviewImg = <StaticImage src='../../images/casestudy/bedfinder/design.png' alt='' objectFit='cover' className='image-teaser' />
const bedfinderOverviewImg2 = <StaticImage src='../../images/casestudy/bedfinder/design.png' alt='' objectFit='cover' className='image' />
const bedfinderMarketingImg = <StaticImage src='../../images/casestudy/bedfinder/checkitout.png' alt='' objectFit='cover' className='image' />

const arzOverviewImg = <StaticImage src='../../images/casestudy/arz/arz6.png' alt='' objectFit='cover' className='image' />
const arzMarketingImg = <StaticImage src='../../images/casestudy/arz/arz7.png' alt='' objectFit='cover' className='image' />

import etravelMessages from '../../components/CaseStudy/messages/Etravel/Etravel.lang'
const etravelOverviewImg = <StaticImage src='../../images/casestudy/etravel/design.png' alt='' objectFit='cover' className='image' />
const etravelMarketingImg = <StaticImage src='../../images/casestudy/etravel/checkitout.png' alt='' objectFit='cover' className='image-teaser' />
const etravelMarketingImg2 = <StaticImage src='../../images/casestudy/etravel/checkitout.png' alt='' objectFit='cover' className='image' />

import plattenplanerMessages from '../../components/CaseStudy/messages/Plattenplaner/Plattenplaner.lang'
const plattenplanerOverviewImg = <StaticImage src='../../images/casestudy/plattenplaner/plattenplaner-responsive@2x.png' alt='' objectFit='cover' className='image' />
const plattenplanerMarketingImg = <StaticImage src='../../images/casestudy/plattenplaner/plattenplaner-marketing@2x.png' alt='' objectFit='cover' className='image-teaser' />
const plattenplanerMarketingImg2 = <StaticImage src='../../images/casestudy/plattenplaner/plattenplaner-marketing@2x.png' alt='' objectFit='cover' className='image' />

import weekendMessages from '../../components/CaseStudy/messages/Weekendcom/Weekendcom.lang'
const weekendOverviewImg = <StaticImage src='../../images/casestudy/weekendcom/design.png' alt='' objectFit='cover' className='image' />
const weekendMarketingImg = <StaticImage src='../../images/casestudy/weekendcom/checkitout.png' alt='' objectFit='contain' className='image-teaser' />
const weekendMarketingImg2 = <StaticImage src='../../images/casestudy/weekendcom/checkitout.png' alt='' objectFit='cover' className='image' />

import ytravelMessages from '../../components/CaseStudy/messages/Ytravel/Ytravel.lang'
const ytravelOverviewImg = <StaticImage src='../../images/casestudy/ytravel/design.png' alt='' objectFit='cover' className='image' />
const ytravelMarketingImg = <StaticImage src='../../images/casestudy/ytravel/ytravel-checkitout.png' alt='' objectFit='cover' className='image' />

import dertouristikMessages from '../../components/CaseStudy/messages/DerTouristik/DerTouristik.lang'
const dertouristikOverviewImg = <StaticImage src='../../images/casestudy/dertouristik/whitelabels.png' alt='' objectFit='cover' className='image-teaser' />
const dertouristikOverviewImg2 = <StaticImage src='../../images/casestudy/dertouristik/checkitout.png' alt='' objectFit='cover' className='image' />
const dertouristikMarketingImg = <StaticImage src='../../images/casestudy/dertouristik/checkitout.png' alt='' objectFit='cover' className='image' />

import BCUXMessages from '../../components/CaseStudy/messages/BCUX/BCUX.lang'
const BCUXOverviewImg = <StaticImage src='../../images/casestudy/bcux/uxOverview@2x.png' alt='' objectFit='cover' className='image-teaser' />

import BCsalesMessages from '../../components/CaseStudy/messages/BCsales/BCsales.lang'
const BCsalesOverviewImg = <StaticImage src='../../images/casestudy/bcsales/sales_2.png' alt='' objectFit='cover' className='image-teaser' />

import XmasMessages from '../../components/XmasPage/XmasPage.lang'
const XmasOverviewImg = <StaticImage src='../../images/xmas/xmas-2022-case-study-teaser-large.png' alt='' objectFit='cover' className='image' />
const XmasMarketingImg = <StaticImage src='../../images/xmas/xmas-2022-case-study-teaser-image.jpg' alt='' objectFit='cover' className='image' />

import SmmMessages from '../../components/CaseStudy/messages/Smm/smm.lang'
const SmmOverviewImg = <StaticImage src='../../images/casestudy/smm/plattenplaner.png' alt='' objectFit='cover' className='image-teaser' />
const SmmMarketingImg = <StaticImage src='../../images/casestudy/smm/marketing.png' alt='' objectFit='cover' className='image' />

import mitsubishiMessages from '../../components/CaseStudy/messages/Mitsubishi/mitsubishi.lang'
const mitsubishiOverviewImg = <StaticImage src='../../images/casestudy/mitsubishi/marketing.png' alt='' objectFit='cover' className='image-teaser' />
const mitsubishiMarketingImg = <StaticImage src='../../images/casestudy/mitsubishi/marketing.png' alt='' objectFit='cover' className='image' />
/* eslint-enable max-len */

export const projectsList = {
  xmas: {
    name: XmasMessages.name,
    title: XmasMessages.title,
    description: XmasMessages.overviewText,
    localLink: '/xmas/',
    overviewImage: XmasOverviewImg,
    marketingImage: XmasMarketingImg
  },
  dertouristik: {
    name: dertouristikMessages.name,
    title: dertouristikMessages.heroTitle,
    description: dertouristikMessages.heroDescription,
    localLink: '/case-study/dertouristik/',
    overviewImage: dertouristikOverviewImg,
    overviewImage2: dertouristikOverviewImg2,
    marketingImage: dertouristikMarketingImg
  },
  lhh: {
    name: lhhMessages.name,
    title: lhhMessages.heroTitle,
    description: lhhMessages.heroDescription,
    localLink: '/case-study/hlx-touristik/',
    overviewImage: lhhOverviewImg,
    overviewImage2: lhhOverviewImg2,
    marketingImage: lhhMarketingImg
  },
  aldiana: {
    name: aldianaMessages.name,
    title: aldianaMessages.heroTitle,
    description: aldianaMessages.heroDescription,
    localLink: '/case-study/aldiana/',
    overviewImage: aldianaOverviewImg,
    overviewImage2: aldianaOverviewImg2,
    marketingImage: aldianaMarketingImg
  },
  bedfinder: {
    name: bedfinderMessages.name,
    title: bedfinderMessages.heroTitle,
    description: bedfinderMessages.heroDescription,
    localLink: '/case-study/bedfinder/',
    overviewImage: bedfinderOverviewImg,
    overviewImage2: bedfinderOverviewImg2,
    marketingImage: bedfinderMarketingImg
  },
  arz: {
    // name:bedfinderMessages.name,
    title: bedfinderMessages.heroTitle,
    description: bedfinderMessages.heroDescription,
    localLink: '/case-study/arz/',
    overviewImage: arzOverviewImg,
    marketingImage: arzMarketingImg
  },
  etravel: {
    name: etravelMessages.name,
    title: etravelMessages.heroTitle,
    description: etravelMessages.heroDescription,
    localLink: '/case-study/etravel/',
    overviewImage: etravelOverviewImg,
    marketingImage: etravelMarketingImg2,
    marketingImage2: etravelMarketingImg
  },
  plattenplaner: {
    name: plattenplanerMessages.name,
    title: plattenplanerMessages.heroTitle,
    description: plattenplanerMessages.heroDescription,
    localLink: '/case-study/plattenplaner/',
    overviewImage: plattenplanerOverviewImg,
    marketingImage2: plattenplanerMarketingImg,
    marketingImage: plattenplanerMarketingImg2,
  },
  weekend: {
    name: weekendMessages.name,
    title: weekendMessages.heroTitle,
    description: weekendMessages.heroDescription,
    localLink: '/case-study/weekendcom/',
    overviewImage: weekendOverviewImg,
    marketingImage: weekendMarketingImg2,
    marketingImage2: weekendMarketingImg
  },
  ytravel: {
    name: ytravelMessages.name,
    title: ytravelMessages.heroTitle,
    description: ytravelMessages.heroDescription,
    localLink: '/case-study/y-travel/',
    overviewImage: ytravelOverviewImg,
    marketingImage: ytravelMarketingImg
  },
  BCUX: {
    name: BCUXMessages.name,
    title: BCUXMessages.heroTitle,
    description: BCUXMessages.heroDescription,
    localLink: '/contact/',
    overviewImage: BCUXOverviewImg
  },
  BCsales: {
    name: BCsalesMessages.name,
    title: BCsalesMessages.heroTitle,
    description: BCsalesMessages.heroDescription,
    localLink: '/contact/',
    overviewImage: BCsalesOverviewImg
  },
  smm: {
    name: SmmMessages.name,
    title: SmmMessages.heroTitle,
    description: SmmMessages.heroDescription,
    localLink: '/case-study/s-reisewelt/',
    overviewImage: SmmOverviewImg,
    marketingImage: SmmMarketingImg
  },
  mitsubishi: {
    name: mitsubishiMessages.name,
    title: mitsubishiMessages.heroTitle,
    description: mitsubishiMessages.heroDescription,
    localLink: '/case-study/mitsubishi/',
    overviewImage: mitsubishiOverviewImg,
    marketingImage: mitsubishiMarketingImg
  }
}
